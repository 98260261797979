(function ($) {
    /**
     * When starting a simulation on home page (/) Simulator
     * check if a project is chosen, else display error message in a popup.
     * Also, check if profession and desired amount to borrow are set.
     */
    $('form#cmvm-simulator').submit(function (event) {
        var proceedForm = true;
        var isSimulationStart = $(this).find('input[name=simulation-start]').length > 0;
        // Check the current step is simulation-start.
        if (isSimulationStart) {
            var fundingSubject1 = $(this).find('input[type=radio][name="funding-subject[]"]:checked');
            var fundingSubject2 = $(this).find('select#funding').val();
            // No funding subject found: display an error message.
            if (fundingSubject1.length === 0 && !fundingSubject2) {
                alert("Veuillez sélectionner un projet à financer");
                proceedForm = false;
            }
        }
        // Avoid form sending
        if (!proceedForm) {
            event.preventDefault();
        }
        return proceedForm;
    });
})(jQuery);
